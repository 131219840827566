import * as React from "react";
import * as HtmlHelper from "../common/html-helper";
import { ICategory, IMobileOnlyBreadcrumbProps, IMobileOnlyBreadcrumbState } from "./mobile-only-breadcrumb.d";
import "./mobile-only-breadcrumb.scss";

export default class MobileOnlyBreadcrumb extends
    React.Component<IMobileOnlyBreadcrumbProps, IMobileOnlyBreadcrumbState> {
    private breadcrumbItmesRef = React.createRef<HTMLDivElement>();
    private innerWrapperRef = React.createRef<HTMLDivElement>();

    private currentMargin = 0;
    private originalWrapperWidth = 0;

    private Hammer;
    private mc;
    private resizeListener;

    constructor(props) {
        super(props);
        this.state = {
            shouldShowGradient: false,
        };
        this.backToHistory = this.backToHistory.bind(this);
    }

    public componentDidMount() {
        if (this.innerWrapperRef.current !== null) {
            const element = this.innerWrapperRef.current;
            const showGradient: boolean = element.scrollWidth > element.clientWidth;
            this.setState({ shouldShowGradient: showGradient });
        }

        if (typeof window !== "undefined") {
            this.Hammer = require("hammerjs");
            if (this.innerWrapperRef.current) {
                this.mc = this.Hammer(this.innerWrapperRef.current);
                this.mc.get("pan").set({
                    direction: this.Hammer.DIRECTION_HORIZONTAL,
                });
                this.mobileScroll();
            }
        }

        this.resizeListener = window.addEventListener("resize", () => {
            this.mobileScroll();
        });
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.resizeListener, false);
    }

    public render() {
        const restBreadcrumb: JSX.Element[] = [];
        const breadcrumbCount = this.props.breadcrumbs.length;
        const firstItem = this.props.breadcrumbs[0];
        const BreadcrumbContainerTag = this.props.showH1Tag ? "h1" : "div";
        let index;
        for (index = 1; index < breadcrumbCount; index++) {
            const breadcrumb = this.props.breadcrumbs[index];
            if (breadcrumb) {
                const breadcrumbNode = this.breadcrumbElement(breadcrumb);
                // the breadcrumb-wrapper direction is rtl
                restBreadcrumb.unshift(breadcrumbNode);
            }
        }
        if (firstItem !== null)
            return (
                <div
                    data-testid="mobile-only-breadcrumb-container"
                    className="mobile-only-breadcrumb-container"
                >
                    <BreadcrumbContainerTag ref={this.breadcrumbItmesRef} className="b_c_items">
                        <a
                            className={`${restBreadcrumb.length > 0 ? "first-item" : "first-item only"}`}
                            key={firstItem.name}
                            href={"category://" + firstItem.navigationKey}
                        >
                            {HtmlHelper.decodeHTML(firstItem.name)}
                            {
                                (restBreadcrumb.length > 0 &&
                                        this.state.shouldShowGradient) &&
                                    <div className="gradient"/>
                            }
                        </a>
                        <div
                            className="mobile-only-breadcrumb-wrapper"
                            ref={this.innerWrapperRef}
                        >
                                {this.button()}
                                {
                                    restBreadcrumb.length > 0 && (
                                        restBreadcrumb
                                    )
                                }
                        </div>
                    </BreadcrumbContainerTag>
                    <div className="mobile_border"/>
                </div>
            );
        else
            return null;
    }

    private breadcrumbElement(breadcrumb: ICategory): JSX.Element {
        return (
            <a
                className="breadcrumb-category"
                key={breadcrumb.name}
                href={"category://" + breadcrumb.navigationKey}
            >
                {HtmlHelper.decodeHTML(breadcrumb.name)}
            </a>
        );
    }

    private button() {
        if (this.props.viewType === "Detail")
            // WARNING: DO NOT use back-button
            // It will cause script class leak and let,
            // bootstrap or something trigger backbutton function again
            return (
                <div
                    className="breadcrumb-back-button-mobile"
                    data-testid="breadcrumb-back-button-mobile"
                    onClick={this.backToHistory}
                >
                    {HtmlHelper.decodeHTML(this.props.l10n.back) + "    >"}
                </div>
            );
    }

    // manual scroll
    private mobileScroll() {
        if (this.innerWrapperRef.current) {
            const content = this.innerWrapperRef.current;
            this.originalWrapperWidth = content.clientWidth;
            const maxMargin = this.originalWrapperWidth - content.scrollWidth;
            // be aware that in root category we do not have a child element (Clothing)
            const lastElement: HTMLElement = content.firstElementChild as HTMLElement;

            if (maxMargin < 0) {
                this.mc.on("panmove", (event) => {
                    this.currentMargin -= event.deltaX;
                    if (this.currentMargin < maxMargin)
                        this.currentMargin = maxMargin;
                    if (this.currentMargin >= 0)
                        this.currentMargin = 0;
                    lastElement.style.marginRight = this.currentMargin.toString() + "px";
                });
                this.mc.on("panend", () => {
                    if (this.currentMargin === maxMargin)
                        this.setState({ shouldShowGradient: false });
                    else
                        this.setState({ shouldShowGradient: true });
                });
            } else {
                if (lastElement)
                    lastElement.style.marginRight = maxMargin.toString();

                this.setState({ shouldShowGradient: false });

                this.mc.off("panmove");
                this.mc.off("panend");
            }
        }
    }

    // Business logic
    private backToHistory() {
        if (typeof window !== "undefined") {
            const referrerUrl = document.referrer.length > 0 ? new URL(document.referrer) : null;
            if (referrerUrl?.hostname === window.location.hostname && referrerUrl.href !== window.location.href)
                // back if: on the same host but not exactly the same path
                window.history.back();
            else
                window.location.href = this.props.fallbackLink;
        }
    }
}
