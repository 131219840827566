import * as React from "react";
import * as FragmentPlaceholderTypes from "./fragment-placeholder.d";

export default class FragmentPlaceholder extends React.Component<FragmentPlaceholderTypes.IFragmentPlaceholderProps> {

    constructor(props) {
        super(props);
    }

    public render() {
        return (<div dangerouslySetInnerHTML={this.createFragmentPlaceholder()} />);
    }

    private createFragmentPlaceholder() {
        const ignoreErrors: string = this.props.ignoreErrors ? "true" : "false";
        const fallback: string = this.props.fallbackSrc != null ? ` fallback-src="${this.props.fallbackSrc}"` : "";

        return {__html: `<!--#fragment src="${this.props.src}" ignore-errors="${ignoreErrors}"${fallback}-->`};
    }
}
