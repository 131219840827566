import classNames from "classNames";
import * as React from "react";
import { isFirefox } from "react-device-detect";
import FragmentPlaceholder from "../common/fragment-placeholder/fragment-placeholder";
import * as HtmlHelper from "../common/html-helper";
import MobileOnlyBreadcrumb from "../mobile-only-breadcrumb/mobile-only-breadcrumb";
import MobileOnlyProductResult from "../mobile-only-product-result/mobile-only-product-result";
import { IBreadcrumbProps, IBreadcrumbState, ICategory, IProductFinder } from "./breadcrumb.d";
import "./breadcrumb.scss";

export default class Breadcrumb extends React.Component<IBreadcrumbProps, IBreadcrumbState> {
    private elementRef = React.createRef<HTMLDivElement>();
    private searchResultRef = React.createRef<HTMLDivElement>();
    private breadcrumbItmesRef = React.createRef<HTMLDivElement>();
    private innerWrapperRef = React.createRef<HTMLDivElement>();
    private isFinder: boolean;
    private lastKnownScrollPosition = 0;

    constructor(props) {
        super(props);
        this.state = {
            hideWrapper: false,
            shouldShowGradient: false,
        };
        this.backToHistory = this.backToHistory.bind(this);
        this.openProductFinder = this.openProductFinder.bind(this);
        this.handlePageScrollOrResize = this.handlePageScrollOrResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        // To fix lint error "Exceeds maximum line length of 120"
        // tslint:disable-next-line:max-line-length
        this.isFinder = this.props.viewType === "TrouserFinder" || this.props.viewType === "JacketFinder" || this.props.viewType === "ShoeFinder";

        if (typeof window !== "undefined") {
            // For SSR reason.
            // As stickyfill has window dependency, we can only use it when there is window
            const Stickyfill = require("stickyfilljs");
            const sticky: HTMLElement = document.getElementsByClassName("scope-breadcrumb")[0] as HTMLElement;
            Stickyfill.add(sticky);
        }

        if (isFirefox) {
            window.shell.subscribeTo("ESPP.MainSidePanel.Opened", () => {
                this.firefoxHack(true);
            }, "breadcrumb-msp-opened");
            window.shell.subscribeTo("ESPP.MainSidePanel.Closed", () => {
                this.firefoxHack(false);
            }, "breadcrumb-msp-closed");
        }
    }

    public destroy() {
        window.shell.unsubscribeFrom("ESPP.FilterAndSort.UpdateSearchResult", "ProductFinder");
    }

    public handlePageScrollOrResize() {
        const hideWrapper = typeof window !== "undefined" ? window.scrollY !== 0 : false;
        this.setState({ hideWrapper });
    }

    public componentDidMount() {
        if (this.innerWrapperRef.current !== null) {
            const element = this.innerWrapperRef.current;
            const showGradient: boolean = element.scrollWidth > element.clientWidth;
            this.setState({ shouldShowGradient: showGradient });
        }

        if (this.props.enableRedesignCategoryPageAndSearchPage) {
            window.addEventListener("scroll", this.handlePageScrollOrResize);
            window.addEventListener("resize", this.handlePageScrollOrResize);
        }
    }

    public componentWillUnmount() {
        if (this.props.enableRedesignCategoryPageAndSearchPage) {
            window.removeEventListener("scroll", this.handlePageScrollOrResize);
            window.removeEventListener("resize", this.handlePageScrollOrResize);
        }
    }

    public render() {
        return (
            <div
                ref={this.elementRef}
                className={classNames({ ["fix"]: this.props.viewType === "Detail" })}
            >
                <div
                    className={classNames(`breadcrumb-wrapper ${this.props.portal}`,
                    {
                        ["hide-wrapper"]: this.state.hideWrapper,
                    },
                )}
                >
                    {this.content()}
                    {
                        this.props.mainSidePanelIsAvailable &&
                        this.renderButtonContainer()
                    }
                </div>
                {
                    (this.isFinder && this.props.isMobile) &&
                    (
                        <MobileOnlyProductResult
                            viewType={this.props.viewType}
                            productFinder={this.props.productFinder}
                            closeNavigationKey={this.props.breadcrumbs[1].navigationKey}
                        />
                    )
                }
                <hr className={classNames("border", { ["kiosk"]: !!this.props.wwsId })} />
                {
                    !this.props.isMobile &&
                    <div className="sticky-gradient" />
                }
            </div>
        );
    }

    private renderButtonContainer() {
        return (
            <div className="button-container">
                {this.props.enableRedesignCategoryPageAndSearchPage === false ||
                (this.props.enableRedesignCategoryPageAndSearchPage && this.isFinder)
                    ? this.productFinder() : <></>}
                {this.button()}
            </div>
        );
    }

    private content() {
        if (this.props.breadcrumbs !== null && this.props.viewType !== "Search")
            if (this.props.isMobile)
                return (
                    <MobileOnlyBreadcrumb
                        viewType={this.props.viewType}
                        l10n={this.props.l10n}
                        breadcrumbs={this.props.breadcrumbs}
                        fallbackLink={this.props.fallbackLink}
                        allQueryParams={this.props.allQueryParams}
                        showH1Tag={this.props.showH1Tag}
                    />
                );
            else return this.breadcrumbs();
        else if (this.props.viewType === "Search")
            return this.searchResultString();
        else
            return (
                <div>
                    no breadcrumbs found (probably because the URL misses the navigationKey parameter)
                    e.g. <a href="http://localhost:40310/breadcrumb?seoSlug=arbeitskleidung">here</a>
                </div>
            );
    }

    private breadcrumbs() {
        const restBreadcrumb: JSX.Element[] = [];
        const breadcrumbCount = this.props.breadcrumbs.length;
        const firstItem = this.props.breadcrumbs[0];
        const BreadcrumbContainerTag = this.props.showH1Tag ? "h1" : "div";
        let index;
        for (index = 1; index < breadcrumbCount; index++) {
            const breadcrumb = this.props.breadcrumbs[index];
            if (breadcrumb) {
                const breadcrumbNode = this.breadcrumbElement(breadcrumb);
                // the breadcrumb-wrapper direction is rtl
                restBreadcrumb.unshift(breadcrumbNode);
            }
        }
        if (firstItem !== null)
            return (
                <div
                    data-testid="breadcrumb-container"
                    className="breadcrumb-container"
                >
                    <BreadcrumbContainerTag ref={this.breadcrumbItmesRef} className="b_c_items">
                        <a
                            className={`${restBreadcrumb.length > 0 ? "first-item" : "first-item only"}`}
                            key={firstItem.name}
                            href={"category://" + firstItem.navigationKey}
                        >
                            {HtmlHelper.decodeHTML(firstItem.name)}
                            {
                                (restBreadcrumb.length > 0 &&
                                    this.state.shouldShowGradient) &&
                                <div className="gradient" />
                            }
                        </a>
                        {
                            restBreadcrumb.length > 0 && (
                                <div
                                    className="breadcrumb-category-wrapper"
                                    ref={this.innerWrapperRef}
                                    onScroll={this.handleScroll}
                                >
                                    {restBreadcrumb}
                                </div>
                            )
                        }
                    </BreadcrumbContainerTag>
                    <div className="mobile_border" />
                </div>
            );
        else
            return null;
    }

    private handleScroll(event) {
        if (event.target.scrollLeft + event.target.scrollWidth <= event.target.clientWidth + 1)
            this.setState({ shouldShowGradient: false });
        else if (!this.state.shouldShowGradient)
            this.setState({ shouldShowGradient: true });
    }

    private breadcrumbElement(breadcrumb: ICategory): JSX.Element {
        return (
            <a
                className="breadcrumb-category"
                key={breadcrumb.name}
                href={"category://" + breadcrumb.navigationKey}
            >
                {HtmlHelper.decodeHTML(breadcrumb.name)}
            </a>
        );
    }

    private button() {
        if (this.props.viewType === "Detail")
            // WARNING: DO NOT use back-button
            // It will cause script class leak and let,
            // bootstrap or something trigger backbutton function again

            if (!this.props.isMobile)
                return (
                    <span
                        className="breadcrumb-back-button"
                        data-testid="breadcrumb-back-button"
                        onClick={this.backToHistory}
                    >
                        {"<   " + HtmlHelper.decodeHTML(this.props.l10n.back)}
                    </span>
                );

        if (this.props.isAlternative && this.props.resultCount > 0 || this.props.enableRedesignCategoryPageAndSearchPage
            && this.props.viewType === "Category")
            return null;
        else if (!this.props.isMobile && !this.isFinder)
            return (
                this.props.enableRedesignCategoryPageAndSearchPage ?
                    (
                        <FragmentPlaceholder
                            src={"/ESPP1.ArticleTileService/fas-button" + this.props.allQueryParams}
                            ignoreErrors={true}
                        />
                    )
                    :
                    (
                        <FragmentPlaceholder
                            src={"/ESPP.FilterAndSort/fas-button" + this.props.allQueryParams}
                            ignoreErrors={true}
                        />
                    )
            );
    }

    private productFinder() {
        if (this.props.viewType !== "Detail" &&
            this.props.productFinder &&
            this.props.productFinder.length > 0 &&
            !this.props.isMobile) {

            const finder: IProductFinder = this.props.productFinder[0];
            return (
                <div className="product-finder-button">
                    <div
                        data-testid="product-finder-logo-title"
                        className="product-finder-logo-title"
                        onClick={this.openProductFinder}
                    >
                        <div className="logo" />
                        <span className="button-title">{HtmlHelper.decodeHTML(finder.label)}</span>
                    </div>
                    {
                        this.isFinder
                            ? (
                                <a
                                    href={"category://" + this.props.breadcrumbs[1].navigationKey}
                                    className="close-icon-area"
                                >
                                    <div className="close-icon" />
                                </a>
                            )
                            : null
                    }
                </div>
            );
        } else
            return null;
    }

    private searchResultString() {
        if (!this.props.isAlternative)
            if (typeof window !== "undefined") {
                const firstPart = this.props.l10n.searchString.split("<big>")[0];
                const lastPart = this.props.l10n.searchString.split("</big>")[1];
                const searchString = this.props.l10n.searchString;
                const highlightPart = searchString
                    .substring(searchString.indexOf(">") + 1, searchString.lastIndexOf("<"));
                return (
                    <div className="search-result">
                        <span>{firstPart}</span>
                        <big>{highlightPart}</big>
                        <span ref={this.searchResultRef}>{lastPart}</span>
                    </div>
                );
            } else {
                // For server side rendering
                const ssrString = "~~~$$$raw:LocalizationFilterSortBreadcrumb.SearchResult$$$~" +
                    this.props.searchQuery + "~0~~~";
                return (
                    <div className="search-result">
                        {ssrString}
                    </div>
                );
            }
        else if (this.props.resultCount > 0) {
            const unitString: string = this.props.resultCount > 1
                ? this.props.l10n.searchResults
                : this.props.l10n.searchResult;
            const searchResultString: string =
                this.props.resultCount.toString() + " " + unitString;
            const queryString: string = this.props.searchQuery + ": ";
            return (
                <div className="alternative-search-result">
                    <div className="search-query">
                        {queryString}
                    </div>
                    <div className="result-count">
                        {searchResultString}
                    </div>
                </div>
            );
        } else
            return null;
    }

    private firefoxHack(isOpen: boolean) {
        const element: HTMLElement = document.getElementsByClassName("scope-breadcrumb")[0] as HTMLElement;
        if ((parseInt(document.body.style.top, 10) < 0) && isOpen)
            element.classList.toggle("breadcrumb-sticky", true);
        else
            element.classList.toggle("breadcrumb-sticky", false);
    }

    // Business logic
    private backToHistory() {
        if (typeof window !== "undefined") {
            const referrerUrl = document.referrer.length > 0 ? new URL(document.referrer) : null;

            if (referrerUrl?.hostname === window.location.hostname && referrerUrl.href !== window.location.href)
                // back if: on the same host but not exactly the same path
                window.history.back();
            else
                window.location.href = this.props.fallbackLink;

        }

    }

    private openProductFinder() {
        if (typeof window !== "undefined" && window.shell) {
            const finder: IProductFinder = this.props.productFinder[0];
            window.shell.publishTo("ESPP.MainSidePanel.ShouldOpen", finder.key);
        }
    }
}
