import * as React from "react";
import * as HtmlHelper from "../common/html-helper";
import { IMobileOnlyProductResultProps, IProductFinder } from "./mobile-only-product-result.d";
import "./mobile-only-product-result.scss";

export default class MobileOnlyProductResult extends
    React.Component<IMobileOnlyProductResultProps> {

    constructor(props) {
        super(props);
        this.state = {
            shouldShowGradient: false,
        };

        this.openProductFinder = this.openProductFinder.bind(this);
    }

    public render() {
        const finder: IProductFinder = this.props.productFinder[0];
        return (
            <div className="product-finder-button-container">
                <div
                    data-testid="product-finder-button"
                    className="product-finder-button"
                    onClick={this.openProductFinder}
                >
                    <div className="logo" />
                    <span className="button-title">{HtmlHelper.decodeHTML(finder.label)}</span>
                </div>
                <a
                    href={"category://" + this.props.closeNavigationKey}
                    className="close-icon-area"
                >
                    <div className="close-icon" />
                </a>
            </div>
        );
    }

    private openProductFinder() {
        if (typeof window !== "undefined" && window.shell) {
            const finder: IProductFinder = this.props.productFinder[0];
            window.shell.publishTo("ESPP.MainSidePanel.ShouldOpen", finder.key);
        }
    }

}
